<template>
  <div id="user-profile-edit">
    <b-btn
      v-if="!this.$route.query.explorerui"
      aria-label="back"
      variant="link"
      class="back-button"
      @click="clickBack"
    >
      <i class="material-icons">arrow_back</i>
    </b-btn>
    <p
      ref="desc"
      tabindex="0"
      class="profile-description"
    >
      {{ description }}
    </p>
    <form
      id="userProfileForm"
      :action="`/api/account/${accountCode}`"
      method="put"
    >
      <b-form-group
        v-if="type === 'name' || type === 'phone' || type === 'email' || type === 'language'"
        id="user-profile-edit-group"
        :invalid-feedback="invalidFeedback"
        :state="state"
      >
        <div v-if="type === 'name'">
          <FormGroup
            for="firstName"
            label="First Name*"
          >
            <FormInput
              bind-id="firstName"
              get="firstName"
              set="UPDATE_FIRST_NAME"
            />
          </FormGroup>
          <FormGroup
            for="lastName"
            label="Last Name"
          >
            <FormInput
              bind-id="lastName"
              get="lastName"
              set="UPDATE_LAST_NAME"
            />
          </FormGroup>
        </div>
        <div
          v-if="type === 'phone'"
          class="d-flex justify-content-between"
        >
          <FormGroup
            for="countryCode"
            class="w-25-35"
          >
            <FormSelect
              :options="countryCodeOptions"
              bind-id="countryCode"
              default="+1"
              get="countryCode"
              set="UPDATE_COUNTRY_CODE"
            />
          </FormGroup>
          <FormGroup
            for="phoneNumber"
            class="w-75-65"
          >
            <FormInput
              type="tel"
              bind-id="phoneNumber"
              get="phoneNumber"
              set="UPDATE_PHONE_NUMBER"
              placeholder="Mobile Number"
            />
          </FormGroup>
        </div>
        <FormGroup for="email">
          <b-form-input
            v-if="type === 'email'"
            id="user-profile-edit-email"
            v-model="email"
            :state="state"
            :readonly="disabled"
            invalid-feedback="Error: This email is invalid"
            type="email"
            placeholder="Email Address"
            class="form-input-field"
          />
        </FormGroup>
        <div v-if="type === 'language'">
          <FormGroup
            for="language"
            label="Agent Language"
          >
            <FormSelect
              :options="languageOptions"
              bind-id="language"
              get="formattedLanguage"
              set="UPDATE_LANGUAGE"
            />
          </FormGroup>
          <FormGroup
            v-if="language !== 'English'"
            for="englishSecondLanguage"
            :label="englishSecondLanguageLabel"
          >
            <FormSelect
              :options="englishSecondLanguageOptions"
              bind-id="englishSecondLanguage"
              get="formattedEnglishSecondLanguage"
              set="UPDATE_ENGLISH_SECOND_LANGUAGE"
            />
          </FormGroup>
        </div>
      </b-form-group>
      <!-- EDIT BILLING INFO AND PAYMENT METHOD -->
      <div
        v-if="type === 'billing'"
        id="profile-edit-payment-container"
      >
        <!-- BILLING INFORMATION -->
        <h2 class="profile-heading">
          BILLING INFORMATION
        </h2>

        <FormGroup
          for="firstName"
          label="First Name"
          class="w-100"
        >
          <FormInput
            :state="null"
            name="firstName"
            bind-id="firstName"
            data-recurly="first_name"
            invalid-feedback="First Name Required."
            invalid-feedback-id="first-name-invalid-feedback"
            get="ccFirstName"
            set="UPDATE_CC_FIRST_NAME"
            required
          />
        </FormGroup>
        <FormGroup
          for="lastName"
          label="Last Name"
          class="w-100"
        >
          <FormInput
            :state="null"
            name="lastName"
            bind-id="lastName"
            data-recurly="last_name"
            invalid-feedback="Last Name Required."
            invalid-feedback-id="last-name-invalid-feedback"
            get="ccLastName"
            set="UPDATE_CC_LAST_NAME"
            required
          />
        </FormGroup>
        <FormGroup
          for="address1"
          label="Address"
          class="w-100"
        >
          <FormInput
            :state="null"
            name="address1"
            bind-id="address1"
            data-recurly="address1"
            invalid-feedback="Address Required."
            invalid-feedback-id="address1-invalid-feedback"
            get="address1"
            set="UPDATE_ADDRESS1"
            required
          />
        </FormGroup>
        <div class="d-flex justify-content-between">
          <FormGroup
            for="country"
            label="Country"
            class="w-50"
          >
            <FormSelect
              :options="countryOptions"
              :state="null"
              name="country"
              bind-id="country"
              data-recurly="country"
              invalid-feedback="Country Required."
              invalid-feedback-id="country-invalid-feedback"
              get="country"
              set="UPDATE_COUNTRY"
              required
            />
          </FormGroup>
          <FormGroup
            for="state"
            label="State/Province/Region"
            class="w-50"
          >
            <FormSelect
              :options="stateOptions"
              :state="null"
              name="state"
              bind-id="state"
              data-recurly="state"
              invalid-feedback="State Required."
              invalid-feedback-id="state-invalid-feedback"
              get="state"
              set="UPDATE_STATE"
              required
            />
          </FormGroup>
        </div>
        <div class="d-flex justify-content-between">
          <FormGroup
            for="city"
            label="City"
            class="w-50"
          >
            <FormInput
              :state="null"
              name="city"
              bind-id="city"
              data-recurly="city"
              invalid-feedback="City Required."
              invalid-feedback-id="city-invalid-feedback"
              get="city"
              set="UPDATE_CITY"
              required
            />
          </FormGroup>
          <FormGroup
            for="zip"
            label="Zip or Postal Code"
            class="w-50"
          >
            <FormInput
              :state="null"
              name="zip"
              bind-id="zip"
              data-recurly="postal_code"
              invalid-feedback="Zip or Postal Code Required."
              invalid-feedback-id="zip-invalid-feedback"
              get="zip"
              set="UPDATE_ZIP"
              required
            />
          </FormGroup>
        </div>
        <br>
        <!-- CREDIT CARD INFORMATION -->
        <h2 class="profile-heading">
          CREDIT CARD INFORMATION
        </h2>
        <FormGroup
          for="cc"
          label="Card Number"
          class="w-100"
        >
          <div
            class="recurly-field"
            role="none"
            data-recurly="number"
          />
        </FormGroup>
        <div class="d-flex justify-content-between">
          <FormGroup
            for="expiryMonth"
            label="Expiration Month (MM)"
            class="w-50"
          >
            <div
              class="recurly-field"
              role="none"
              data-recurly="month"
            />
          </FormGroup>
          <FormGroup
            for="expiryYear"
            label="Expiration Year (YYYY)"
            class="w-50"
          >
            <div
              class="recurly-field"
              role="none"
              data-recurly="year"
            />
          </FormGroup>
        </div>
        <FormGroup
          for="cvv"
          label="CVV"
          class="w-50"
        >
          <FormInput
            :state="null"
            name="cvv"
            bind-id="cvv"
            data-recurly="cvv"
            invalid-feedback="CVV Required."
            invalid-feedback-id="cvv-invalid-feedback"
            get="cvv"
            set="UPDATE_CVV"
            required
          />
        </FormGroup>
        <input
          id="token"
          ref="token"
          type="hidden"
          name="recurly-token"
          data-recurly="token"
        >
        <b-btn
          type="submit"
          class="profile-button w-100"
          variant="primary"
        >
          {{ buttonText }}
        </b-btn>
        <b-btn
          id="billingButton"
          hidden
          @click="clickUpdate"
        />
      </div>
      <!-- submission -->
      <b-btn
        v-if="type !== 'billing'"
        type="submit"
        class="profile-button w-100"
        variant="primary"
        @click="clickUpdate"
      >
        {{ buttonText }}
      </b-btn>
    </form>
    <script2 src="https://js.recurly.com/v4/recurly.js" />
    <script2 src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
    <!-- eslint-disable -->
    <script2>
      $(document).ready(function () {
        recurly.configure({
          publicKey: '{{ publicKey }}',
        });

        recurly.on('field:submit', function (event) {
          event.preventDefault();
        });

        $('#userProfileForm').on('submit', function (event) {
          event.preventDefault();
          var form = this;

          recurly.token(form, function (err, token) {
            if (err) {
              console.log(err);
              alert(err.message + ' Please make sure your credit card number and expiration date are correct.');
            } else {
              $('#token').val(token.id);
              $('#billingButton').click();
            }
          });
        });
      });
    </script2>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { validateEmail } from '@/utils/validation';

import FormGroup from '../Form/FormGroup.vue';
import FormInput from '../Form/FormInput.vue';
import FormSelect from '../Form/FormSelect.vue';

export default {
  name: 'UserProfileEdit',
  components: {
    FormGroup,
    FormInput,
    FormSelect,
  },
  data() {
    return {
      type: this.$route.params.type,
      state: null,
      invalidFeedback: '',
      disabled: false,
      email: '',
      token: '',
      publicKey: process.env.VUE_APP_RECURLY_PUBLIC_KEY,
    };
  },
  computed: {
    ...mapGetters([
      'firstName',
      'lastName',
      'countryOptions',
      'countryCodeOptions',
      'countryCode',
      'stateOptions',
      'phoneNumber',
      'phone',
      'accountCode',
      'ccCensored',
      'ccFirstName',
      'ccLastName',
      'expiryMonth',
      'expiryYear',
      'cvvCensored',
      'language',
      'englishSecondLanguage',
    ]),
    description() {
      switch (this.type) {
        case 'name':
          return '';
        case 'email':
          return 'Update your email address below. We\'ll email you a link to verify your address.';
        case 'phone':
          return 'Update your mobile number below. We\'ll text you a code to verify your number.';
        case 'billing':
          return 'Update your payment method and billing address below.';
        case 'language':
          return '';
        default:
          return 'Update your profile below.';
      }
    },
    buttonText() {
      switch (this.type) {
        case 'name':
          return 'UPDATE NAME';
        case 'email':
          return 'UPDATE EMAIL';
        case 'phone':
          return 'UPDATE MOBILE NUMBER';
        case 'billing':
          return 'UPDATE PAYMENT METHOD';
        case 'language':
          if (this.language === 'French') {
            return 'ENREGISTRER LA LANGUE';
          } else if (this.language === 'Spanish') {
            return 'ACTUALIZAR LENGUAJE';
          } else {
            return 'UPDATE LANGUAGE';
          }
        default:
          return 'UPDATE PROFILE';
      }
    },
    languageOptions() {
      return ['English', 'Español', 'Français'];
    },
    englishSecondLanguageLabel() {
      if (this.language === 'French') {
        return 'Parlez vous anglais?';
      }
      return '¿Hablas inglés?';
    },
    englishSecondLanguageOptions() {
      if (this.language === 'French') {
        return ['Non', 'Oui'];
      }
      return ['No', 'Sí'];
    },
  },
  mounted() {
    const timer = setInterval(() => {
      window.scrollTo(0, 0);
      this.focusTop();
      clearInterval(timer);
    }, 800);
  },
  methods: {
    focusTop() {
      if (this.$refs.desc) this.$refs.desc.focus();
    },
    clickBack() {
      this.$store.dispatch('getUserProfile');
      this.$router.go(-1);
    },
    async clickUpdate() {
      this.state = null;
      this.invalidFeedback = '';
      this.disabled = true;
      switch (this.type) {
        case 'name':
          if (!this.firstName) {
            this.state = false;
            this.invalidFeedback = 'Error: First name is required. Enter your first name and try again.';
            this.disabled = false;
            break;
          } else {
            try {
              await this.$store.dispatch('updateName');
              // eslint-disable-next-line
              await confirm('Name has been successfully updated! You will now be redirected back to the profile page.');
              await this.$router.push(`/${this.$route.fullPath.split('/')[1]}/profile`);
            } catch (error) {
              this.state = false;
              this.invalidFeedback = `Error: ${error.errorMessage}`;
            }
            this.disabled = false;
            break;
          }
        case 'email':
          if (!validateEmail(this.email)) {
            this.state = false;
            this.invalidFeedback = 'Error: This email address is invalid';
            this.disabled = false;
            break;
          } else {
            try {
              const response = await this.$store.dispatch('updateEmail', { email: this.email });
              if (response) {
                this.$store.commit('UPDATE_EMAIL', this.email);
                await this.$router.push(`/${this.$route.fullPath.split('/')[1]}/${this.$route.fullPath.split('/')[2]}/verify/${this.type}`);
              } else {
                this.state = false;
                this.invalidFeedback = 'Error: This email address is invalid';
              }
            } catch (error) {
              this.state = false;
              this.invalidFeedback = `Error: ${error.errorMessage}`;
            }
            this.disabled = false;
            break;
          }
        case 'phone':
          if (this.phoneNumber.length < 7) {
            this.state = false;
            this.invalidFeedback = 'Error: invalid number';
            this.disabled = false;
          } else {
            try {
              await this.$store.dispatch('userVerify');
              this.state = null;
              this.invalidFeedback = '';
              await this.$router.push(`/${this.$route.fullPath.split('/')[1]}/${this.$route.fullPath.split('/')[2]}/verify/${this.type}`);
            } catch (error) {
              console.error(error);
              this.state = false;
              this.invalidFeedback = `Error: ${error.errorMessage}`;
              this.disabled = false;
            }
          }
          break;
        case 'billing':
          try {
            const response = await this.$store.dispatch('putUserBilling', this.$refs.token.value);
            if (response) {
              await this.$store.dispatch('getUserBilling');
              // eslint-disable-next-line
              await confirm('Update success! You will now be redirected back.');
              this.$router.go(-1);
            }
          } catch (error) {
            console.error(error);
            this.state = false;
            this.invalidFeedback = `Error: ${error.errorMessage}`;
            // eslint-disable-next-line
            alert(this.invalidFeedback);
          }
          break;
        case 'language':
          try {
            const payload = [{ value: this.language }];
            if (this.language !== 'English' && this.englishSecondLanguage) {
              payload.push({ value: 'English' });
            }

            await this.$store.dispatch('updatePropertyValue', { propertyKey: 'preferredLang', data: payload });
            // eslint-disable-next-line
            await confirm('Language has been successfully updated! You will now be redirected back to the profile page.');
            await this.$router.push(`/${this.$route.fullPath.split('/')[1]}/profile`);
          } catch (error) {
            this.state = false;
            this.invalidFeedback = `Error: ${error.errorMessage}`;
          }
          this.disabled = false;
          break;
        default:
          console.log('update profile');
          this.disabled = false;
      }
    },
  },
};
</script>

<style lang="scss">
#user-profile-edit {
  padding: 15px;
  margin-top: 55px;

  label {
    text-align: left;
  }

  .profile-description {
    padding: 0 20px 20px 20px;
  }

  #profile-edit-payment-container {
    text-align: left;
  }

  .back-button {
    position: absolute;
    top: 30px;
    left: 15px;
    display: inline;
    color: white;
  }

  #user-profile-edit-group {
    margin: 0 0 10px 0 !important;
  }
}
</style>
